// @ts-ignore
declare global {
    interface Window {
        changeTab: (evt: any, tabId: string) => void;
    }
}

function changeTab(evt, tabId) {
    let i, x, tabs;
    x = document.getElementsByClassName("display-content");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    tabs = document.getElementsByClassName("tab-bar-item");
    for (i = 0; i < x.length; i++) {
        tabs[i].className = tabs[i].className.replace(" tab-active", "");
    }
    document.getElementById(tabId).style.display = "block";
    evt.currentTarget.className += " tab-active";
}

window.changeTab = changeTab;
